.Hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 114px);
  padding-left: 50px;
  padding-right: 24px;

  .tabletHeading {
    display: none;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    width: 1440px;
    height: 100%;
    max-height: 800px;
    padding-left: 200px;
    background-image: url(../../../../assets/images/hero-background.svg),
      url(../../../../assets/images/hero-background-left.svg);
    background-repeat: no-repeat;
    background-position: center right 0, center left 0;

    .foreground {
      position: absolute;
      top: 60px;
      right: 65px;
      width: 670px;
      height: 100%;
      background-image: url(../../../../assets/images/hero-foreground.svg);
      background-repeat: no-repeat;
      animation: float 3s ease-in-out infinite;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 385px;

    &::after {
      content: '';
      position: absolute;
      top: -42px;
      left: -63px;
      display: block;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-image: url(../../../../assets/images/triangle.svg);
      transform: rotate(10deg);
    }

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      bottom: -65px;
      display: block;
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-image: url(../../../../assets/images/circle.svg);
      transform: rotate(10deg);
    }

    h1 {
      margin: 0;
      margin-bottom: 35px;
      font-size: 50px;
      font-family: 'Museo Sans Rounded';
      color: rgb(54, 81, 138);
      font-weight: 900;
    }

    p {
      margin: 0;
      margin-bottom: 35px;
      font-size: 23px;
      font-family: 'Museo Sans Rounded';
      color: rgb(54, 81, 138);
      line-height: 1.2;
      text-align: left;
      font-weight: 500;
    }

    .buttonContainer {
      margin-left: auto;
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@media (max-width: 1415px) {
  .Hero {
    .content {
      width: 300px;
    }
  }
}

@media (max-width: 1320px) {
  .Hero {
    .container {
      padding-left: 130px;
    }
  }
}

@media (max-width: 1255px) {
  .Hero {
    .content {
      width: 300px;

      p {
        width: 250px;
      }

      .buttonContainer {
        visibility: hidden;
      }
    }
  }
}

@media (max-width: 1190px) {
  .Hero {
    .content {
      width: 300px;

      p {
        width: 250px;
        visibility: hidden;
      }

      .buttonContainer {
        visibility: hidden;
      }
    }
  }
}

@media (max-height: 500px) and (min-width: 600px) {
  .Hero {
    margin-bottom: 30px;

    .container {
      .foreground {
        left: unset !important;
        right: 20px !important;
        width: 32% !important;
      }

      .content {
        padding-top: 0;
        background-size: 30% !important;
        background-position: center right 20px;
        align-items: unset;

        .innerContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }

        h1 {
          width: 65%;
          margin-bottom: 25px;
        }
        p {
          visibility: visible;
          width: 65%;
          margin-bottom: 0;
          text-align: center;
          font-size: 20px;
        }
      }
    }
  }
}

@media (min-width: 450px) and (min-height: 500px) and (max-width: 1024px) {
  .Hero {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .tabletHeading {
      margin: 0;
      margin-bottom: 35px;
      font-size: 50px;
      font-family: 'Museo Sans Rounded';
      color: rgb(54, 81, 138);
      font-weight: 900;
      position: relative;
      display: initial;
      z-index: 1;
      width: 80%;
      font-size: 36px;
      text-align: center;
    }

    .container {
      flex: 1;
      .content {
        background-size: contain;

        h1 {
          display: none;
        }
      }
      .foreground {
        background-size: contain !important;
      }
    }
  }
}
@media (max-width: 1024px) {
  .Hero {
    height: 100svh;
    padding-top: 79px;
    padding-left: 0;
    padding-right: 0;

    .container {
      position: relative;
      align-items: unset;
      justify-content: unset;
      width: 100%;
      max-height: unset;
      padding-left: 0;
      background-image: none;

      .foreground {
        width: unset;
        height: unset;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-position: center;
        background-size: 80%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
      background-image: url(../../../../assets/images/hero-background-left.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 95%;
      &::after {
        display: none;
      }

      &::before {
        display: none;
      }

      h1 {
        position: relative;
        z-index: 1;
        width: 80%;
        font-size: 36px;
        text-align: center;
      }
    }
  }
}
